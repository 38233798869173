import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Cityscape Drive Rentals
			</title>
			<meta name={"description"} content={"Our selection of cars matches the rhythm of city life, offering a perfect blend of mobility and style. Each rental here is an opportunity to immerse yourself in the heart of the city with ease and elegance.\n"} />
			<meta property={"og:title"} content={"Cityscape Drive Rentals"} />
			<meta property={"og:description"} content={"Our selection of cars matches the rhythm of city life, offering a perfect blend of mobility and style. Each rental here is an opportunity to immerse yourself in the heart of the city with ease and elegance.\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b7ae60009abe001f027d1c"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});